import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'The Legacy of Intentional Segregation',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/VOA6C1GvdvY',
  },
};

export default props => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      We continue to lead deeply segregated lives.
    </p>
    <p>
      The legacy of segregation is palpable across the American South and has
      enormous implications for how people lead their daily lives. From
      redlining and housing policy to physical separations by highways or
      railroad tracks and transit policies, physical separation is a real-life
      consequence of decades-old decisionmaking. An overwhelming number of
      residents interviewed stated that they are segregated not only where they
      live, but also where they work. Residents also noted the clear and often
      abrupt divides between neighborhoods along racial lines, and the
      concentration of poverty in predominantly black neighborhoods.{' '}
    </p>
    <p>
      According to the US Partnership on Mobility from Poverty, a typical white
      person in America lives in a neighborhood that is 75 percent white and
      only 8 percent black. But a typical black person in America lives in a
      neighborhood that is only 35 percent white and 45 percent black. When
      drilling down further, people of color are overrepresented in high-poverty
      census tracts. In the U.S.,a low-income black person is more than three
      times more likely than a white person is to live in a neighborhood with a
      poverty rate of 40 percent or more, and a low-income Latino person is more
      than twice as likely to live in such a neighborhood. These statistics show
      that racial residential segregation and racialized concentrated poverty
      persist today.
    </p>
    <p>
      Residential segregation matters because where you live affects your access
      to education, employment, transportation, healthcare, and so many other
      aspects of daily life that are often taken for granted. In a report by The
      Century Foundation, researchers found that the separateness of black and
      white families has contributed to the enormous racial wealth gap and the
      unequal access to good public education. Higher levels of education and
      income typically translate into higher levels of wealth and less exposure
      to concentrated poverty. For black people, residential segregation by race
      imposes an additional penalty. Black households headed by an individual
      with a bachelor’s degree have just two-thirds of the wealth, on average,
      of white households headed by an individual who lacks a high school
      degree. While median income for black households is 59 percent that of
      white households, black median household net worth is just 8 percent of
      white median household net worth.
    </p>
    <Quote
      quote="But because of the way this city is sort of segregated, most whites don’t necessarily have to deal with black folk on a daily basis. And so that in essence breeds this sense of racism because we don’t deal with each other, but it’s not always racism, sometimes it’s just, I don’t know you and I don’t deal with you."
      person="Black male, 43"
      title="Knoxville"
    />
    <Quote
      quote="When folks feel like they only belong in certain parts of town…it should be painful for an entire community if people don’t feel the freedom of movement within their own communities."
      person="White female, 34"
      title="Charleston, WV"
    />
    <Quote
      quote="I’m a student of urban planning, and in my studies of that here in this city, race played quite a specific role on how we rated neighborhoods, on what neighborhoods were deemed slums, on what neighborhoods were razed, on what neighborhoods got sewage, plumbing, etc. And it still continues to this day."
      person="Male"
      title="Richmond"
    />
  </Modal>
);
